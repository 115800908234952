import React from "react"
import styled from "@emotion/styled"
import Title from "../components/title"
import { MessagePageInterface } from "../@types/components/messagePage"
import tw from "twin.macro"

/**
 * Used for pages with little content, ie 404 & form sent pages
 *
 * @param title
 * @param children
 * @constructor
 */
const MessagePage: React.FC<MessagePageInterface> = ({ title, children }) => {
  const Section = styled.section`
    ${tw`flex flex-col text-center font-content`}
  `

  const Subtitle = styled.span`
    ${tw`
      text-4xl tracking-widest font-hairline mt-20
      md:text-5xl
      `}
  `

  return (
    <Section>
      <Title title={title} />
      <Subtitle>{children}</Subtitle>
    </Section>
  )
}

export default MessagePage
