import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MessagePage from "../components/messagePage"

/**
 * 404 page
 *
 * @constructor
 */
const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <MessagePage title={"NOT FOUND"}>
        You just hit a route that doesn&#39;t exist... the sadness.
      </MessagePage>
    </Layout>
  )
}

export default NotFoundPage
