import React from "react"
import styled from "@emotion/styled"
import { TitleInterface } from "../@types/components/title"
import tw from "twin.macro"

const Title: React.FC<TitleInterface> = ({ title }) => {
  const Title = styled.h1`
    ${tw`
    text-6xl border-b font-headers 
    `}
  `
  return <Title>{title}</Title>
}

export default Title
